import axios from 'axios';
var baseUrl = 'http://122.170.104.127:3000/';

export const sendEmail = (contactUs) => {
    console.log("🚀 ~ file: api.service.js:5 ~ sendEmail ~ contactUs:", contactUs)
    return axios.post(baseUrl + '/send-email/', contactUs)
        .then(function (response) {
            return response
        })
        .catch(function (error) {
            console.log(error);
            return error
        });
}


