import React from "react";
import './HomeAboutSection.scss'
import 'font-awesome/css/font-awesome.min.css';
import { Images } from "../../config/images";
import { Container } from "react-bootstrap";

function HomeAboutSection() {
  return (
    <div className="video-wrapper">
      <Container>
        <video autoPlay muted loop playsInline preload="metadata">
          {/* <source src="http://www.adrianparr.com/download/keyboard-video.mp4" type="video/mp4" /> */}
          <source src={Images.HomeVideo} type="video/mp4" />
        </video>
      </Container>
    </div >
  );
};

export default HomeAboutSection