import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Images } from "../../config/images";
import { Navbar, Container, } from 'react-bootstrap';
import './header.scss'
import 'font-awesome/css/font-awesome.min.css';
const Header = () => {
    const location = useLocation();
    const urlPath = location.pathname;
  
  console.log("urlPath",urlPath)
    const [position, setPosition] = useState(window.pageYOffset)
    const [visible, setVisible] = useState(true)
    const listInnerRef = useRef();
    useEffect(() => {
        const handleScroll = () => {
            let moving = window.pageYOffset

            setVisible(position > moving);
            setPosition(moving)
        };
        window.addEventListener("scroll", handleScroll);
        return (() => {
            window.removeEventListener("scroll", handleScroll);
        })
    })
    const [isActive, setActive] = useState("false");

    const handleToggle = () => {
        setActive(!isActive);
    };
    const cls = position > 120 ? visible ? "visible" : "hidden" : "";
    
    const handleClick = (event) => {
        event.preventDefault(); // Prevents the default behavior of the click event
        // Handle the click event here
    };
    return (
        <div className={"header-main " + cls} ref={listInnerRef}>
            <Navbar collapseOnSelect expand="lg">
                <Container>
                    <div className="header-item-container">
                        <div className="logo">
                            <Navbar.Brand href="/"><img src={Images.DigitalDilemma} alt="Digital Dilemma" /></Navbar.Brand>
                        </div>
                    </div>
                </Container>
            </Navbar>
        </div >
    );
}

export default Header;