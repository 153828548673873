import React, { useState, useRef } from "react";
import './ContactSection.scss'
import { Col, Container, Row } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import Button from 'react-bootstrap/Button';
import { Link, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { sendEmail } from "../../services/api.service";
import { Images } from "../../config/images";
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha"
function ContactSection() {
    const location = useLocation();
    const urlPath = location.pathname;
    const captchaRef = useRef(null)
    const [values, setValues] = useState({
        name: "",
        email: "",
        contact_number: "",
        subject: "",
        message: "",
        token: ""
    });

    const [formerrors, setFormErrors] = useState({
        name: "",
        email: "",
        contact_number: "",
        token: ""
    });

    const handleCaptchaChange = (event) => {
        setValues((values) => ({
            ...values,
            token: event,
        }));
        setFormErrors((formerrors) => ({
            ...formerrors,
            token: ''
        }))
    }
    const handleChange = (event) => {
        if (event.target.name == 'contact_number') {
            if(event.target.value.length > 0){
            const PHONE_REGEX = /^[0-9\b]+$/;
            if (event.target.value != '' && !PHONE_REGEX.test(event.target.value)) {
                return false
            }
            }
        }
        setValues((values) => ({
            ...values,
            [event.target.name]: event.target.value,
        }));
        const error = validate(event.target.name, event.target.value);
        if (error && error.length > 0) {
            setFormErrors((formerrors) => ({
                ...formerrors,
                [event.target.name]: error,
            }))
        } else {
            setValues((values) => ({
                ...values,
                [event.target.name]: event.target.value,
            }));
            setFormErrors((formerrors) => ({
                ...formerrors,
                [event.target.name]: '',
            }))
        }
    };

    const validate = (name, value) => {
        switch (name) {
            case "name":
                if (!value) {
                    return "Name is required.";
                } else {
                    return "";
                }
            case "email":
                if (!value) {
                    return "Email is required.";
                } else if (
                    !value.match(/^[a-z0-9]([a-z0-9_\-\.]*)@([a-z0-9_\-\.]*)(\.[a-z]{2,4}(\.[a-z]{2}){0,2})$/i)
                ) {
                    return "Please enter a valid email.";
                } else {
                    return "";
                }
            case "contact_number":
                if(value.length > 0){
                if (!(/^[0-9\b]+$/).test(value)) {
                    return "Please enter valid Contact number";
                } else if (value.length != 10) {
                    return "Please enter 10 digit.";
                }
                else {
                    return "";
                }
                }else{
                    return "";
                }
            case "token":
                if (!value) {
                    return "Please verify the captcha.";
                } else {
                    return "";
                }
            default: {
                return "";
            }
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        let validationErrors = {};
        Object.keys(values).forEach(inputName => {
            const error = validate(inputName, values[inputName]);
            if (error && error.length > 0) {
                validationErrors[inputName] = error;
            }
        });
        if (Object.keys(validationErrors).length > 0) {
            setFormErrors(validationErrors);
            return;
        }
        if (values.name && values.email) {
            const data = {
                name: values.name,
                email: values.email,
                contact_number: values.contact_number,
                subject: values.subject,
                message: values.message
            };
            sendEmail(data)
                .then(function (response) {
                    if (response.status) {
                        captchaRef.current.reset();
                        toast.success("Form submitted successfully.");
                    } else {
                        captchaRef.current.reset();
                        toast.error("Something went wrong.");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    captchaRef.current.reset();
                    toast.error("Something went wrong.");
                })
            setFormErrors({
                name: "",
                email: "",
                contact_number: ""
            })
            setValues({
                name: "",
                email: "",
                contact_number: "",
                subject: "",
                message: ""
            })
        }
    };
    return (
        <div className="contact-section">
            <Container>
                <div className="contact-section-container">
                    <div className="contact-section-inner">
                        <a href="https://calendly.com/d/z5z-7sb-z2j/30min" target="_blank">
                            <div className="user-image">
                                <img src={Images.Andrew} />
                            </div>
                            <div className="hire-head">
                                <h2 className="section-title">Talk to</h2>
                                <h1>Andrew</h1>
                                <p>Andrew has been in IT for the past 29 years. Having owned and run Digital Dilemma for the past 14. There's not much he hasn't seen in this industry. Book in a call, he could help point you in the right direction, whatever your requirement.</p>
                            </div>
                        </a>
                    </div>
                    <div className="contact-form-inner">
                        <Form className="contact-form" onSubmit={handleSubmit}>
                            <Row className="m-0">
                                <Col>
                                    <Form.Group className="form-item" controlId="formBasicName">
                                        <Form.Control type="text" placeholder="Name" name="name" value={values.name}
                                            onChange={handleChange} />
                                        {formerrors.name && (
                                            <p className="text-danger">{formerrors.name}</p>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="form-item" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Email" name="email" value={values.email}
                                            onChange={handleChange} />
                                        {formerrors.email && (
                                            <p className="text-danger">{formerrors.email}</p>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <div className="contact-numbe-row">
                                        {/* <div className="contact-numbe-left">
                                            <Form.Select className="form-item" aria-label="Default select example">
                                                <option>+91</option>
                                                <option value="1">+358</option>
                                                <option value="2">+1684</option>
                                                <option value="3">+1264</option>
                                            </Form.Select>
                                        </div> */}
                                        <div className="contact-numbe-right">
                                            <Form.Group className="form-item" controlId="formBasicContactNumber">
                                                <Form.Control type="text" placeholder="Contact Number" name="contact_number" value={values.contact_number}
                                                    onChange={handleChange} maxLength={10} />
                                                {formerrors.contact_number && (
                                                    <p className="text-danger">{formerrors.contact_number}</p>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    {/* <Form.Select className="form-item" aria-label="Default select example">
                                        <option>India</option>
                                        <option value="1">India</option>
                                        <option value="2">India</option>
                                        <option value="3">India</option>
                                    </Form.Select> */}
                                    <Form.Group className="form-item" controlId="formsubject">
                                        <Form.Control type="text" placeholder="Subject" name="subject" value={values.subject}
                                            onChange={handleChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <Form.Group className="form-item" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" placeholder="Message" name="message" rows={3} value={values.message}
                                            onChange={handleChange} />
                                    </Form.Group>

                                </Col>
                            </Row>
                            <Row className="m-0">
                                <Col>
                                    <ReCAPTCHA
                                        style={{ display: "inline-block" }}
                                        sitekey='6LcDpcAlAAAAAC3QEQaUYK9VhCFzdFVPh7g_EIh4'
                                        ref={captchaRef} onChange={handleCaptchaChange} />
                                    {formerrors.token && (
                                        <p className="text-danger">{formerrors.token}</p>
                                    )}
                                </Col>
                            </Row>
                            <div className="submit-btn">
                                <Button variant="primary" type="submit" >Send Message</Button>
                            </div>
                        </Form>
                    </div>
                </div>
                
            </Container>
        </div>
    );
};
export default ContactSection