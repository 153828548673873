export const Images = {
    logo: require('../images/ed-logo.svg').default,
    HomeBannerImg: require('../images/home-banner-img.svg').default,
    FullstackIcon: require('../images/fullstack-icon.png'),
    AngularIcon: require('../images/angular-icon.png'),
    LaravelIcon: require('../images/laravel-icon.png'),
    FlutterIcon: require('../images/flutter-icon.png'),

    // ClientSlider1: require('../images/gatherco-logo.svg').default,
    ClientSlider2: require('../images/bookmyshow-logo.svg').default,
    // ClientSlider3: require('../images/propact-logo.svg').default,
    ClientSlider4: require('../images/zomato-logo.svg').default,
    ClientSlider5: require('../images/locale-logo.png'),
    ClientSlider6: require('../images/myntra-logo.svg').default,

    ClientSlider1: require('../images/gatherco-logo.png'),
    ClientSlider3: require('../images/eddbee-logo.png'),

    TestimonialImage1: require('../images/testimonial-image1.png'),
    TestimonialImage2: require('../images/testimonial-image2.png'),
    TestimonialImage3: require('../images/testimonial-image3.png'),

    HomeVideo: require('../images/home-video.mp4'),
    HomeBannerVideo: require('../images/home-banner-video.mp4'),

    TestimonialAfterIcon: require('../images/testimonial-after-icon.png'),
    TestimonialBeforeIcon: require('../images/testimonial-before-icon.png'),
    RecentBlogsImg: require('../images/recent-blogs-img.png'),
    FootEdLogo: require('../images/foot-ed-logo.svg').default,
    GoodfirmsWebDevelopment: require('../images/goodfirms_web_development.png'),
    ClutchBadge: require('../images/clutch_badge.png'),
    AppFuturaBadge: require('../images/app_futura_badge.png'),
    SelectedFirms: require('../images/selected_firms.png'),
    CantServiceImage: require('../images/cant-service-image.png'),
    SkypeIcon: require('../images/skype-icon.svg').default,
    WhatsappIcon: require('../images/whatsapp-icon.svg').default,
    GmailIcon: require('../images/gmail-icon.svg').default,

    MapIconIndia: require('../images/map-icon-india.png'),
    MapIconSweden: require('../images/map-icon-sweden.png'),
    MapIconAustralia: require('../images/map-icon-australia.png'),
    MapIconGermany: require('../images/map-icon-germany.png'),

    OurCoreValuesIcon: require('../images/our-core-values-icon.svg').default,
    WadeWarren: require('../images/wade-warren.png'),
    RobertFox: require('../images/robert-fox.png'),
    KristinWatson: require('../images/kristin-watson.png'),
    RonaldRichards: require('../images/ronald-richards.png'),
    CourtneyHenry: require('../images/courtney-henry.png'),
    MarvinMcKinney: require('../images/marvin-mckinney.png'),
    ClutchIcon: require('../images/clutch-icon.png'),
    GoodFirmsIcon: require('../images/good-firms-icon.png'),
    AppFuturaIcon: require('../images/app-futura-icon.png'),
    SelectedFirmsIcon: require('../images/selected-firms-icon.png'),

    UiUxGraphicDesign: require('../images/uiux-graphic-design.png'),
    MobileAppDevelopment: require('../images/mobile-app-development.png'),
    WebAppDevelopment: require('../images/web-app-development.png'),
    ECommerceDevelopment: require('../images/e-commerce-development.png'),
    FrontendDevelopment: require('../images/frontend-development.png'),
    CrossPlatformDevelopment: require('../images/cross-platform-development.png'),
    PhpFrameworks: require('../images/service-php-frameworks.png'),
    MicrosoftTechnologies: require('../images/microsoft-technologies.png'),

    GathercoImage: require('../images/gatherco-image.png'),
    Propact: require('../images/propact.png'),
    ArbitrageCrypto: require('../images/arbitrage-crypto.png'),
    ClarenceLifts: require('../images/clarence-lifts.png'),
    HorseMapp: require('../images/horsemapp.png'),
    RealPass: require('../images/realpass.png'),
    LocaleApp: require('../images/locale-app.png'),
    FuneralBookings: require('../images/funeral-bookings.png'),
    ServiceImage: require('../images/service-image.png'),
    ExpertiseAndSkills: require('../images/expertise-and-skills.png'),
    CompetitivePricing: require('../images/competitive-pricing.png'),
    BreakthroughSolutions: require('../images/breakthrough-solutions.svg').default,
    HireDedicated1: require('../images/hire-dedicated1.png'),

    ServiceAre: require('../images/service-are.png'),
    PerksBenefitsIcon1: require('../images/perks-benefits-icon1.jpg'),
    LifeImage1: require('../images/life-image1.png'),
    LifeImage2: require('../images/life-image2.png'),
    LifeImage3: require('../images/life-image3.png'),
    LifeImage4: require('../images/life-image4.png'),
    LifeImage5: require('../images/life-image5.png'),
    LifeImage6: require('../images/life-image6.png'),
    LifeImage7: require('../images/life-image7.png'),
    LifeImage8: require('../images/life-image8.png'),
    LifeImage9: require('../images/life-image9.png'),
    LifeImage10: require('../images/life-image10.png'),
    LifeImage11: require('../images/life-image11.png'),
    mobileDevelopmentBanner: require('../images/mobile-development-banner.png'),
    IphoneAppIcon: require('../images/iphone-app-icon.svg').default,
    AndroidIcon: require('../images/android-icon.svg').default,
    IpadIcon: require('../images/Ipad-icon.svg').default,
    IotIcon: require('../images/Iot-icon.svg').default,
    CrossPlatformDevelopmentBanner: require('../images/cross-platform-development-banner.png'),
    FlutterAppDevelopment: require('../images/flutter-app-development.svg').default,
    IonicIcon: require('../images/ionic-icon.svg').default,
    ReactNativeDevelopment: require('../images/react-native-development.svg').default,
    XamarinAppDevelopment: require('../images/xamarin-app-development.svg').default,
    FrontendDevelopmentBanner: require('../images/frontend-development-banner.png'),
    AngularDevelopmentIcon: require('../images/angular-development-icon.svg').default,
    NodeJSDevelopment: require('../images/NodeJSDevelopment.svg').default,
    VueJSDevelopment: require('../images/VueJSDevelopment.svg').default,
    ReactJSDevelopment: require('../images/ReactJSDevelopment.svg').default,
    LaravelDevelopment: require('../images/LaravelDevelopment.svg').default,
    CodeigniterDevelopment: require('../images/CodeigniterDevelopment.svg').default,
    ZendDevelopment: require('../images/ZendDevelopment.svg').default,
    YiiDevelopment: require('../images/YiiDevelopment.svg').default,
    phpFrameworks: require('../images/php-frameworks.png'),
    MagentoDevelopment: require('../images/MagentoDevelopment.svg').default,
    ShopifyDevelopment: require('../images/ShopifyDevelopment.svg').default,
    WooCommerceDevelopment: require('../images/WooCommerceDevelopment.svg').default,
    WordPressDevelopment: require('../images/WordPressDevelopment.svg').default,
    mobile_app_design: require('../images/mobile_app_design.svg').default,
    responsive_web_design: require('../images/responsive_web_design.svg').default,
    design_prototyping: require('../images/design_prototyping.svg').default,
    psdicon: require('../images/psd-icon.svg').default,
    ASP_NET_Development: require('../images/ASP_NET_Development.svg').default,
    MVC_Dotnet_Development: require('../images/MVC_Dotnet_Development.svg').default,
    hire_ios_developers: require('../images/hire_ios_developers.svg').default,
    hire_android_developer: require('../images/hire_android_developer.svg').default,
    hire_flutter_developers: require('../images/hire_flutter_developers.svg').default,
    hire_ionic_developer: require('../images/hire_ionic_developer.svg').default,
    hire_react_native_developer: require('../images/hire_react_native_developer.svg').default,
    hire_xamarin_developer: require('../images/hire_xamarin_developer.svg').default,
    hire_angular_developer: require('../images/hire_angular_developer.svg').default,
    hire_nodeJS_developer: require('../images/hire_nodeJS_developer.svg').default,
    hire_vuejs_developer: require('../images/hire_vuejs_developer.svg').default,
    hire_reactjs_developer: require('../images/hire_reactjs_developer.svg').default,
    hire_laravel_developer: require('../images/hire_laravel_developer.svg').default,
    hire_codeigniter_developer: require('../images/hire_codeigniter_developer.svg').default,
    hire_zend_developer: require('../images/hire_zend_developer.svg').default,
    hire_yii_developer: require('../images/hire_yii_developer.svg').default,
    hire_magento_developer: require('../images/hire_magento_developer.svg').default,
    hire_shopify_developer: require('../images/hire_shopify_developer.svg').default,
    hire_wooCommerce_developer: require('../images/hire_wooCommerce_developer.svg').default,
    hire_wordPress_developer: require('../images/hire_wordPress_developer.svg').default,
    hire_aspnet_developer: require('../images/hire_aspnet_developer.svg').default,
    hire_mvc_dotnet_developer: require('../images/hire_mvc_dotnet_developer.svg').default,

    portfolio_half: require('../images/half-new-322.png'),
    portfolio_full: require('../images/full-new-322.png'),
    portfolio_main: require('../images/main.png'),
    portfolio_shoes: require('../images/shoes.png'),
    portfolio_gym: require('../images/gym.png'),
    portfolio_car: require('../images/car.png'),
    portfolio_service: require('../images/service.png'),
    portfolio_beach: require('../images/beach.png'),
    portfolio_hotel: require('../images/hotel.png'),
    portfolio_virtual: require('../images/virtual.png'),
    portfolio_resource: require('../images/resource.png'),

    e_commerce: require('../images/e_commerce.png'),
    food_delivery: require('../images/food_delivery.png'),
    blog_server: require('../images/blog-server.png'),
    blogger: require('../images/blogger.png'),
    blog_01: require('../images/blog-01.png'),
    blog_02: require('../images/blog-02.png'),
    blog_03: require('../images/blog-03.png'),
    blog_04: require('../images/blog-04.png'),
    blog_05: require('../images/blog-05.png'),
    blog_06: require('../images/blog-06.png'),
    blog_07: require('../images/blog-07.png'),
    career_banner: require('../images/career-banner.png'),
    career_perk: require('../images/career-perk.svg').default,
    IosDeveloper: require('../images/ios-developer.png'),
    IosDeveloperShape: require('../images/ios-developer-shape.png'),
    IosDeveloperServiceAre: require('../images/ios-developer-servic-are.png'),
    TechnologyFlutter: require('../images/technology-flutter.png'),
    TechnologyReact: require('../images/technology-react.png'),
    TechnologyIconic: require('../images/technology-iconic.png'),
    TechnologySwift: require('../images/technology-swift.png'),
    TechnologyJs: require('../images/technology-js.png'),
    TechnologyHtml: require('../images/technology-html.png'),
    TechnologyObjective: require('../images/technology-objective-c.png'),
    TechnologyXcode: require('../images/technology-xcode.png'),
    PlaceHolderImg: require('../images/placeholder.png'),
    IconProcess: require('../images/icon-process.png'),
    ProcessShape_01: require('../images/process-01.svg').default,
    ProcessShape_02: require('../images/process-02.svg').default,
    ProcessShape_03: require('../images/process-03.svg').default,
    ProcessShape_04: require('../images/process-04.svg').default,
    ProcessShape_05: require('../images/process-05.svg').default,
    DigitalDilemma: require('../images/digital-dilemma.svg').default,
    DigitalDilemma1: require('../images/digital-dilemma.png'),
    Andrew: require('../images/andrew.jpg'),

    
















































}