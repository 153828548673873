import React from "react";
import './OurWorkSection.scss'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import 'font-awesome/css/font-awesome.min.css';
import { Images } from "../../config/images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
function OurWorkSection() {
    {/* ------------------------------------------old s---------------------------- */ }
    const sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    {/* ------------------------------------------old E---------------------------- */ }
    const settings1 = {
        customPaging: function (i) {
            let name = ''
            if (i === 0) {
                name = "GatherCo"
            } else if (i === 1) {
                name = "Horsemapp"
            } else if (i === 2) {
                name = "Propact"
            } else if (i === 3) {
                name = "Crypto Trading"
            } else if (i === 4) {
                name = "Clarence Lifts"
            } else if (i === 5) {
                name = "RealPass"
            } else if (i === 6) {
                name = "Locale App"
            } else if (i === 7) {
                name = "Funeral Bookings"
            } else {
                name = ""
            }
            return (
                // <h2>{name}</h2>
                <a>{name}</a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="portfolio-listing">
            <div className="service">
                <Container>
                    <div className="service-section">
                        <div className="service-are">
                            <h2>Let's discuss your project.</h2>
                            <p>Book in a time with Andrew for a discussion on how our technology services can improve your business.</p>
                            <Button variant="btn btn-secondary" href="/">Talk to Andrew <i className="fa fa-angle-right"></i></Button>
                        </div>
                        <div className="service-image">
                            <img src={Images.Andrew} alt="Service Image" title="Service Image" />
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default OurWorkSection