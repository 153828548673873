import React from "react";
import {Helmet} from "react-helmet";


const Meta = (data) => {
    const metaData = data.data;
    return (
        <div className="application">
            <Helmet>
                <title>{metaData.title}</title>
                <meta name="description" content={metaData.description} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        </div>
    );
  }

  export default Meta;