import React, {useEffect} from 'react';
import './App.scss';
import 'font-awesome/css/font-awesome.min.css';
import { Routes, Route, useLocation } from "react-router-dom";
import Header from './component/Header/header';
import Footer from './component/Footer/footer';
import DigitalDilemma from './Pages/DigitalDilemma/DigitalDilemma';

import { ToastContainer, } from 'react-toastify';
function App() {
  const location = useLocation();
  const urlPath = location.pathname;
  
  return (
    // <BrowserRouter>
    <>
      <ToastContainer position="top-center"
        autoClose={5000}
        hideProgressBar={true}  
        newestOnTop={false}
        closeOnClick
        rtl={false}
        closeButton={false}
        draggable />
      <Header />
      <Routes>
        <Route path="/" element={<DigitalDilemma />} />
      </Routes>
      {urlPath != '/contactus' ? (<Footer />) : null}
    </>
    // </BrowserRouter>
  );
}
export default App;
