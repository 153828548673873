import React from "react";
import './HireServices.scss'
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import 'font-awesome/css/font-awesome.min.css';
import { Images } from "../../config/images";


function HireServices() {
    return (
        <div className="hire-services-section">
            <Container>
                <div className="hire-services">
                    <div className="hire-head">
                        <span>HIRE SERVICES</span>
                        <h2 className="section-title">Looking for dedicated <br />resources?</h2>
                    </div>
                    <div className="services-content">
                        <div className="row m-0">
                            <div className="col-sm-3 col-xl-2 col-12">
                                <div className="service-inner">
                                    <div className="service-icon">
                                        <span className="icon-border">
                                            <img src={Images.AndroidIcon} alt="Android Icon" title="Android Icon" />
                                        </span>
                                    </div>
                                    <div className="service-text">
                                        <h3>ANDROID<br />DEVELOPERS</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-xl-2 col-12">
                                <div className="service-inner">
                                    <div className="service-icon">
                                        <span className="icon-border">
                                            <img src={Images.IphoneAppIcon} alt="IOS Icon" title="IOS Icon" />
                                        </span>
                                    </div>
                                    <div className="service-text">
                                        <h3>IOS<br />DEVELOPERS</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-xl-2 col-12">
                                <div className="service-inner">
                                    <div className="service-icon">
                                        <span className="icon-border">
                                            <img src={Images.FlutterIcon} alt="FlutterIcon" title="Flutter Icon" />
                                        </span>
                                    </div>
                                    <div className="service-text">
                                        <h3>FLUTTER<br />DEVELOPERS</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-xl-2 col-12">
                                <div className="service-inner">
                                    <div className="service-icon">
                                        <span className="icon-border">
                                            <img src={Images.FullstackIcon} alt="FullstackIcon" title="Fullstack Icon" />
                                        </span>
                                    </div>
                                    <div className="service-text">
                                        <h3>FULLSTACK<br />DEVELOPERS</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-xl-2 col-12">
                                <div className="service-inner">
                                    <div className="service-icon">
                                        <span className="icon-border">
                                            <img src={Images.AngularIcon} alt="AngularIcon" title="Angular Icon" />
                                        </span>
                                    </div>
                                    <div className="service-text">
                                        <h3>ANGULAR<br />DEVELOPERS</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-xl-2 col-12">
                                <div className="service-inner">
                                    <div className="service-icon">
                                        <span className="icon-border">
                                            <img src={Images.LaravelIcon} alt="Laravel Icon" title="Laravel Icon" />
                                        </span>
                                    </div>
                                    <div className="service-text">
                                        <h3>LARAVEL<br />DEVELOPERS</h3>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="services-btn">
                        <Button variant="btn btn-secondary" target="_blank" href="https://calendly.com/d/z5z-7sb-z2j/30min">Talk to Andrew <i className="fa fa-angle-right"></i></Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default HireServices