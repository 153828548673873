import React from "react";
import './Testimonial.scss'
import { Container } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import { Images } from "../../config/images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonial() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        arrows: false,
        cssEase: 'linear'
    };
    return (
        <div className="testimonial">
            <Container>
                <div className="testimonial-inner">
                    <div className="left-titel">
                        <h2>What Our <br /> Clients Say!</h2>
                    </div>
                    <div className="testimonial-slider">
                        <Slider {...settings}>
                            <div className="slider-box">
                                <div className="testimonial-content">
                                    <div>
                                        <h3>Real Pass - <a className="testimonial-link" target="_blank" href="https://www.real-pass.com"> www.real-pass.com</a></h3>
                                        <p>After a couple of failed attempts by previous developers, I finally found Andrew and the team to help. They excelled in design and delivery and I am now launched with a build I am proud of. I highly recommend digidi.co to anyone looking for software development services.</p>
                                    </div>
                                </div>
                                <div className="client-details">
                                    <img src={Images.PlaceHolderImg} alt="testimonial-image1" title="Aaron Clarke" />
                                    <div className="client-name">
                                        <h3>Sam Prosser - Director, Real Pass</h3>
                                        <p>Brisbane, Australia</p>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-box">
                                <div className="testimonial-content">
                                    <div>
                                        <h3>Innovative Utility - <a className="testimonial-link" target="_blank" href="https://iuoutcomes.com">iuoutcomes.com</a></h3>
                                        <p>Andrew and the team took my complex master's thesis in heavy asset management and turned it into a simple to use application. With my clients in defence, it needed to be robust and up to the job. The app hasn't skipped a beat and we're working hard on pushing it into new defence categories.</p>
                                    </div>
                                </div>
                                <div className="client-details">
                                    <img src={Images.PlaceHolderImg} alt="testimonial-image2" title="Ashley Morris" />
                                    <div className="client-name">
                                        <h3>Tony Bannister-Tyrell - Director Innovative Utility</h3>
                                        <p>Sydney, Australia</p>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-box">
                                <div className="testimonial-content">
                                    <div>
                                        <h3>GatherCo Australia - <a className="testimonial-link" target="_blank" href="https://www.gatherco.com.au">www.gatherco.com.au</a></h3>
                                        <p>We are a stone and artisan building material importer in Sydney Australia. Our clients are architects and builders, they have a keen eye for detail. We have been working with digidi.co for the past 3 years building what we believe to be the best app in the industry. Our clients can select multiple products from around the world, pay and track them from a quarry in Morocco to the site in Toorak. The feedback has been phenomenal and the future is bright.</p>
                                    </div>
                                </div>
                                <div className="client-details">
                                    <img src={Images.PlaceHolderImg} alt="testimonial-image3" title="Jenson Bell" />
                                    <div className="client-name">
                                        <h3>Jenson Bell</h3>
                                        <p>Director</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default Testimonial