import React from "react";
import { Container } from 'react-bootstrap';
import './BannerSection.scss'
const BannerSection = () => {
  return (
    <div className="home-banner-section">
      <Container>
        <div className="home-banner-container">
          <div className="row align-items-md-center h-100 m-0">
            <div className="col-md-12 p-0">
              <div className="banner-inner">
                <h1 className="section-title">At <span className="title-gradient">digidi.co</span>, we understand the power of <br /> <span className="title-gradient">technology</span> and its ability to shape the future of your <span className="title-gradient">business.</span></h1>
                  
              </div>
            </div>
          </div>
        </div>
      </Container >
    </div >
  );
}

export default BannerSection;