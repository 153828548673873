import React from "react";
import { NavLink,} from "react-router-dom";
import './footer.scss'
import { Col, Container, Row } from "react-bootstrap";
import ContactSection from "../ContactSection/ContactSection";
import { Images } from "../../config/images";
import 'font-awesome/css/font-awesome.min.css';
const Footer = () => {
    
    
    return (
        <div className="footer-section">
            <ContactSection />
            <div className="footer-navigation">
                <Container>
                    <div className="address-row-wrap">
                        <Row className="m-0">
                            <Col className="p-0">
                                <div className="foot-address-col">
                                    <a href="/" className="foot-logo">
                                        <img src={Images.DigitalDilemma} alt="DigitalDilemma" title="Digital Dilemma" />
                                    </a>                                    
                                    <p>We support innovative, forward-thinking Australian companies of all sizes looking for an edge over their competitors. If you can think it, we can make it happen. Let’s build something remarkable together!</p>
                                </div>
                            </Col> 
                            <Col className="p-0">
                                <Row className="m-0">    
                                    {/* <Col className="p-0">
                                        <div className="social-media-new">
                                            <h4>Follow us on</h4>
                                            <ul className="social-media-icons">
                                                <li>
                                                    <a href="https://www.facebook.com/" target={"_blank"} rel="noopener noreferrer"><span className="fa fa-facebook-square"></span></a>
                                                </li>
                                                <li>
                                                    <a href="https://twitter.com/" target={"_blank"} rel="noopener noreferrer"><span className="fa fa-twitter"></span></a>
                                                </li>
                                                <li>
                                                    <a href="https://in.linkedin.com/" target={"_blank"} rel="noopener noreferrer"><span className="fa fa-linkedin"></span></a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/" target={"_blank"} rel="noopener noreferrer"><span className="fa fa-instagram"></span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col> */}
                                </Row>
                            </Col>
                            <Col className="p-0">
                                <div className="address-box">
                                    <h3>Say Hello!</h3>
                                    <ul>
                                        <li><a href="skype:live:andyarlo?chat"><img src={Images.SkypeIcon} alt="SkypeIcon" title="Skype Icon" /> andyarlo</a></li>
                                        <li><a href="tel:+61 403 588 599"><img src={Images.WhatsappIcon} alt="WhatsappIcon" title="Whatsapp Icon" /> +61 403 588 599</a></li>
                                        <li><a href="mailto:andrew@digidi.co" target="_blank"><img src={Images.GmailIcon} alt="Gmail Icon" title="Gmail Icon" /> andrew@digidi.co</a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>                        
                    </div>
                    <div className="footer-copyright">
                        <div className="row m-0 justify-content-between">
                            <div className="col-xxl-6 col-12 p-0">
                                
                                <p>© Copyright 2023 Digital Dilemma PTY LTD. All Rights Reserved</p>
                            </div>
                            <div className="col-md-6 col-lg-6 p-0">                                
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default Footer;