import React from "react";
import './EngagwmentModels.scss'
import { Container } from 'react-bootstrap';
// `import Button from 'react-bootstrap/Button';`
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'font-awesome/css/font-awesome.min.css';
import { Images } from "../../config/images";

function EngagwmentModels() {
    return (
        <div className="engagwment-section">
            <Container>
                <div className="engagwment-section-container">
                    {/* <div className="section-sub-title">
                        <span>ENGAGEMENT MODELS</span>
                        <h2>Why choose <br />ENCODEDOTS? </h2>
                    </div> */}
                    <div className="engagwment-tabs">
                        <Tabs defaultActiveKey="expertise-and-skills" id="justify-tab-example" className="" justify>
                            <Tab eventKey="expertise-and-skills" title={<span>Expertise and <br /> Skills</span>}>
                                <div className="tab-content-inner">
                                    <div className="content-image">
                                        <img src={Images.ExpertiseAndSkills} alt="expertise-and-skills" />
                                    </div>
                                    <div className="content-box">
                                        <h3>Expertise and<br /> Skills</h3>
                                        <p>At digidi.co we have some of the most industry-acclaimed professionals across skill sets with extensive exposure to many challenging projects.
                                        </p>
                                        <p>With a wealth of experience gained from working on numerous challenging projects, our experts possess a deep understanding of the industry's best practices and are adept at utilizing cutting-edge technologies to create solutions that drive tangible business results.</p>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="competitive-pricing" title={<span>Competitive Pricing</span>}>
                                <div className="tab-content-inner">
                                    <div className="content-image">
                                        <img src={Images.CompetitivePricing} alt="competitive-pricing" />
                                    </div>
                                    <div className="content-box">
                                        <h3>Competitive Pricing</h3>
                                        <p>You have a win-win situation here thanks to our transparent pricing models tailored to your budget and needs. Our pricing designed to be flexible and scalable, ensures that the clients only pay for the required services.</p>
                                        <p>Whether you're a startup with a limited budget or a large enterprise seeking cost-effective solutions, we can work with you to create a pricing model that aligns with your budget and business objectives.</p>                                        
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="breakthrough-solutions" title={<span>Breakthrough Solutions</span>}>
                                <div className="tab-content-inner">
                                    <div className="content-image">
                                        <img src={Images.HireDedicated1} alt="breakthrough-solutions" />
                                    </div>
                                    <div className="content-box">
                                        <h3>Breakthrough Solutions</h3>
                                        <p>Bringing the budding new and futuristic technologies and aligning your digital footprints with the latest trends are our key strengths.</p>
                                        <p>We believe that staying ahead of the curve is critical in today's rapidly evolving business landscape, and we are committed to helping our clients maintain a competitive edge by aligning their digital footprints with the latest trends and technologies.</p>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Container>
        </div>
    );
};
export default EngagwmentModels