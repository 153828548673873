import React from "react";
import './ServicesSection.scss'
import { Container } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'font-awesome/css/font-awesome.min.css';
import { Images } from "../../config/images";
import CountUp from 'react-countup';

function ServicesSection() {
    return (
        <div className="services-section">
            <div className="service-details">
                <Container>
                    <div className="services-section-container">
                        <div className="section-sub-title">
                            <span>SERVICES</span>
                        </div>
                        <Tabs defaultActiveKey="ui-ux-graphic-design" id="justify-tab-example" className="mb-3" justify>
                            <Tab eventKey="ui-ux-graphic-design" title={<h2>UI/UX Graphic Design</h2>}>
                                <div className="tab-content-inner">
                                    <div className="content-box">
                                        <h3>UI/UX Graphic <br /> Design</h3>
                                        <p>Our UI/UX graphic design service goes beyond just creating visually appealing interfaces. We dive deep into user behavior and psychology to craft intuitive and engaging designs that provide a seamless user experience. From wireframing to prototyping, our team ensures that every element of the design is purposeful and enhances the overall user journey.</p>
                                        
                                    </div>
                                    <div className="content-image">
                                        <img src={Images.UiUxGraphicDesign} alt="service-ui-ux-graphic-design" />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="mobile-application-development" title={<h2>Mobile App Development </h2>}>
                                <div className="tab-content-inner">
                                    <div className="content-box">
                                        <h3>Mobile App <br /> Development</h3>
                                        <p>Add unique flair to your digital footprint with a one-of-a-kind mobile app tailored to the taste and expectations of your customers. Whether it is iOS or Android, we bring the latest design-thinking approach to deliver an instantly engaging experience with mobile apps.</p>
                                    </div>
                                    <div className="content-image">
                                        <img src={Images.MobileAppDevelopment} alt="service-mobile-app-development" />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="web-app-development" title={<h2>Web App Development</h2>}>
                                <div className="tab-content-inner">
                                    <div className="content-box">
                                        <h3>Web App <br /> Development</h3>
                                        <p>Do you want a web presence that establishes your thought leadership? Wetake every web project as a challenge to push the boundaries of innovation whether in design or in functionality. In each and every web app, we leverage the latest technologies, tools and design approaches to help you stand out.</p>
                                    </div>
                                    <div className="content-image">
                                        <img src={Images.WebAppDevelopment} alt="service-web-app-development" />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="ecommerce-development" title={<h2>E-commerce Development</h2>}>
                                <div className="tab-content-inner">
                                    <div className="content-box">
                                        <h3>E-commerce <br /> Development</h3>
                                        <p>In the world of online retail, a seamless shopping experience powered by easy discovery and check-out makes all the difference. That’s exactly where our e-commerce development expertise pitches in. With a visually appealing and breathable e-commerce interface perfectly aligned with your brand identity, we help you convert more business and push growth curves.</p>
                                    </div>
                                    <div className="content-image">
                                        <img src={Images.ECommerceDevelopment} alt="service-e-commerce-development" />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="frontend-development" title={<h2>Frontend Development</h2>}>
                                <div className="tab-content-inner">
                                    <div className="content-box">
                                        <h3>Frontend <br /> Development</h3>
                                        <p>Our frontend development services are more than just lines of code - we craft immersive and captivating digital experiences that will leave a lasting impression on your audience. We give no-holds-barred focus to user journey by bringing together design thinking and latest frontend. Trust us to bring your vision to life, and take your online presence to the next level.</p>
                                    </div>

                                    <div className="content-image">
                                        <img src={Images.FrontendDevelopment} alt="service-frontend-development" />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="cross-platform-development" title={<h2>Cross-platform Development</h2>}>
                                <div className="tab-content-inner">
                                    <div className="content-box">
                                        <h3>Cross-platform <br /> Development</h3>
                                        <p>We help you to expand your app presence across multiple OS platforms saving resources and speeding up time to market. By using the latest cross-platform development frameworks we help you deliver native user experience while reusing maximum code across operating systems. </p>
                                    </div>

                                    <div className="content-image">
                                        <img src={Images.CrossPlatformDevelopment} alt="service-cross-platform-development" />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="php-frameworks " title={<h2>PHP Frameworks </h2>}>
                                <div className="tab-content-inner">
                                    <div className="content-box">
                                        <h3>PHP Frameworks </h3>
                                        <p>Are you seeking a PHP solution that goes beyond the ordinary? Look no further than our custom PHP development services powered by expertise across leading PHP frameworks ranging from Laravel to CodeIgniter to CakePHP to Symfony to Yii and many others. Trust us to deliver a scalable, breathable and customizable PHP solution that is as unique and aligned to your digital vision.</p>
                                        {/* <Button variant="secondary" href="/">Explore<i className="fa fa-angle-right"></i></Button> */}
                                    </div>

                                    <div className="content-image">
                                        <img src={Images.PhpFrameworks} alt="service-php-frameworks" />
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </div>
            <div className="countfect">
                <Container>
                    <div className="countfect-section">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="countup-box">
                                    <CountUp end={70} suffix="+" />
                                    <h3>Projects Delivered</h3>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="countup-box">
                                    <CountUp end={35} suffix="+" />
                                    <h3>Happy Clients</h3>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="countup-box">
                                    <CountUp end={50} suffix="+" />
                                    <h3>Team Strength</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};
export default ServicesSection