import React from "react";
import './DigitalDilemma.scss'
import 'font-awesome/css/font-awesome.min.css';
import BannerSection from "../../component/BannerSection/BannerSection";
import HomeAboutSection from "../../component/HomeAboutSection/HomeaboutSection";
import ServicesSection from "../../component/ServicesSection/ServicesSection";
import OurWorkSection from "../../component/OurWorkSection/OurWorkSection";
import HireServices from "../../component/HireServices/HireServices";
import Testimonial from "../../component/Testimonial/Testimonial";
import EngagwmentModels from "../../component/EngagwmentModels/EngagwmentModels";
import Meta from "../../component/Meta/Meta";


function DigitalDilemma() {
  const metaData = {title : "Top Mobile App Development Company | Digidi.co", description: "digidi.co TechnoLabs is one of the well-known software development companies. We offer web development, mobile app development, and digital marketing services."}
  return (
    <div className="HomeNew-wrapper">
      <Meta data={metaData}/>
      <BannerSection />
      <HomeAboutSection />
      <ServicesSection />
      <EngagwmentModels />
      <OurWorkSection />
      <HireServices />
      <Testimonial />
    </div>
  );
};

export default DigitalDilemma